<template>
    <div v-if="!loadData" class="content profile-content">
        <ProfileNavbar :title="$t('profile.title')"></ProfileNavbar>
        <div class="clearfix">
            <div class="title-row" v-show="visibleProfileTitle"> 
                <router-link tag="a" to="/settings-parent" class="title-row-btn d-none">
                    <img src="/images/profile/nottific.svg" alt="">
                </router-link>
                <h3 class="mob-menu-title m-0 p-0 text-center">{{ $t('profile.title') }}</h3>
                <button class="title-row-btn d-none" v-on:click="showButtonsEdit(false, false, true, false, true, true, true, true)">
                    <img src="/images/profile/edit.svg" alt="">
                </button>
            </div>

            <div class="mob-menu-title" v-show="visibleEditProfile">
                <button class="mob-menu-back" v-on:click="showButtonsEdit(true, true, false, true, false, false, false, false, true)">
                    <img src="images/profile/arrow-back.svg">
                </button>
                {{ $t('profile.edit-profile') }}
            </div>
            <div class="profile-edit-form profile-parent-edit-form">
                <h3 class="title text-center text-lg-left">{{ $t('profile.title') }}</h3>
                <p class="hint text-center text-lg-left">{{ $t('profile.description') }}</p>
                <form @submit.prevent="updateProfile()">
                    <div class="row profile-parent-form">
                        <div class="col-md-12 profile-photo-block">
                            <div class="form-group">
                                <el-upload
                                    class="avatar-uploader"
                                    :headers="{Authorization: 'Bearer ' + this.$store.state.auth.token}"
                                    :action="apiRoot + '/api/avatar/update'"
                                    :show-file-list="false"
                                    :on-success="handleAvatarSuccess"
                                    :before-upload="beforeAvatarUpload">
                                    <img v-if="avatar" :src="avatar" class="avatar">
                                    <div v-show="visibleProfileInfo">
                                        <div class="user-name">{{last_name+' '+first_name}}</div>
                                        <div class="user-role">Родитель</div>
                                        <div class="user-desc"></div>
                                    </div>
                                    <div class="d-flex flex-lg-row flex-column mx-auto mx-lg-0">
                                        <label class="avatar-label text-center">{{ $t('profile.avatar') }}</label>
                                        <div class="btn-wrap"  v-show="visiblePhotoDownload">
                                            <button type="button" class="btn btn-light">
                                            <svg width="14" height="21" viewBox="0 0 14 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.70711 6.20711C3.31658 6.59763 2.68342 6.59763 2.29289 6.20711C1.90237 5.81658 1.90237 5.18342 2.29289 4.79289L6.2929 0.79289C6.6834 0.40237 7.3166 0.40237 7.7071 0.79289L11.7071 4.79289C12.0976 5.18342 12.0976 5.81658 11.7071 6.20711C11.3166 6.59763 10.6834 6.59763 10.2929 6.20711L8 3.91421V8.5H6V3.91421L3.70711 6.20711ZM6 8.5H3C1.34315 8.5 0 9.8431 0 11.5V17.5C0 19.1569 1.34315 20.5 3 20.5H11C12.6569 20.5 14 19.1569 14 17.5V11.5C14 9.8431 12.6569 8.5 11 8.5H8V13.5C8 14.0523 7.5523 14.5 7 14.5C6.4477 14.5 6 14.0523 6 13.5V8.5Z" fill="#04C2F4"/>
                                            </svg>
                                            <span>{{ $t('profile.download-photo') }}</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div v-show="visibleMainTitle">
                                        <div class="form-sub-title-mob">{{ $t('profile.main') }}</div>
                                    </div>
                                </el-upload>
                            </div>
                        </div>
                        
                        <div class="col-lg-6 col-md-12 profile-main-info" v-show="visibleMain">
                            <div class="form-group first-el-mob">
                                <label class="form-label">{{ $t('profile.iin-label') }}</label>
                                <el-input maxlength="12" @input="getDataFromIin" :placeholder="$t('profile.iin-placeholder')" v-model="iin"></el-input>
                            </div>
                            <div class="form-group">
                                <label class="form-label">{{ $t('profile.last-name-label') }}</label>
                                <el-input :placeholder="$t('profile.last-name-placeholder')" v-model="last_name"></el-input>
                            </div>
                            <div class="form-group">
                                <label class="form-label">{{ $t('profile.first-name-label') }}</label>
                                <el-input :placeholder="$t('profile.first-name-placeholder')" v-model="first_name"></el-input>
                            </div>
                            <div class="form-group">
                                <label class="form-label">{{ $t('profile.patronymic-label') }}</label>
                                <el-input :placeholder="$t('profile.patronymic-placeholder')" v-model="patronymic"></el-input>
                            </div>
                            <div class="form-group">
                                <label class="form-label">{{ $t('references.gender') }}</label>
                                <el-select
                                    disabled
                                    :placeholder="$t('references.gender')"
                                    v-model="sex">
                                    <el-option
                                        :key="1"
                                        :value="1"
                                        :label="$t('references.gender-male')">
                                    </el-option>
                                    <el-option
                                        :key="2"
                                        :value="2"
                                        :label="$t('references.gender-female')">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <!-- 2 COLUMN -->
                        <div class="col-lg-6 col-md-12 profile-main-info" v-show="visibleMain">
                            <div class="form-group form-group--lg">
                                <label class="form-label">{{ $t('profile.birth-date-label') }}</label>
                                <el-date-picker
                                    disabled
                                    style="width: 100%;"
                                    v-model="birth_date"
                                    type="date"
                                    placeholder="Дата"
                                    format="yyyy.MM.dd"
                                    value-format="yyyy.MM.dd">
                                </el-date-picker>
                            </div>
                            <div class="form-group form-group--lg">
                                <label class="form-label">{{ $t('references.email-label') }}</label>
                                <el-input type="email" 
                                    :placeholder="$t('references.email-placeholder')" 
                                    v-model="email">
                                </el-input>
                            </div>
                            <div class="form-group">
                                <label class="form-label">{{ $t('profile.representative-tel-label') }}</label>
                                <el-input
                                    v-model="phone"
                                    v-mask="'+7 (###) ### ##-##'"
                                    @input="enforcePhoneFormat"
                                    :placeholder="$t('profile.representative-tel-placeholder')">
                                </el-input>
                            </div>
                        </div>
                    </div>
                    <div class="row my-4 " v-show="visibleEditProfileSave">
                        <button type="submit" class="btn btn-primary mx-auto">{{ $t('profile.save-btn') }}</button>
                    </div>
                </form>
            </div>
            <div class="more-info-btns d-none" v-show="visibleBtnsProfile">
                <router-link tag="a" to="/children" class="btn more-info-btn">
                    <img src="/images/profile/mob-notes.svg" class="mr-4">
                    {{ $t('sidebar.children') }}
                </router-link>
                <router-link tag="a" to="/surveys-parent" class="btn more-info-btn">
                    <img src="/images/profile/mob-surveys.svg" class="mr-4">
                    {{ $t('sidebar.surveys') }}
                </router-link>
            </div>

            <div class="more-info-btns" v-show="visibleBtnsEditProfile">
                <button class="btn more-info-btn" v-on:click="showButtonsEdit(false, false, true, false, true, true, true, true, false)">
                    <img src="/images/profile/mob-profile.svg" class="mr-4">
                    {{ $t('profile.main') }}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import {mapActions} from "vuex";
import ProfileNavbar from "@/components/ProfileNavbar";
import VueMask from 'v-mask';
import {minLength, required, email} from "vuelidate/lib/validators";
export default {
    name: 'Account',
    components: {
        ProfileNavbar
    },
    data(){
        return {
           loadData:true,
            loading: false,
            apiRoot: window.API_ROOT,
            avatar:'',
            avatarUrl: '',
            iin: '',
            first_name: '',
            last_name: '',
            patronymic: '',
            sex: '',
            birth_date: '',
            email: '',
            currentPhone: '',
            phone: '',
            internet_connection: '',
            visibleProfileTitle: true,
            visibleEditProfile: false,
            visibleProfileInfo: true,
            visiblePhotoDownload: false,
            visibleMainTitle: false,
            visibleMain: true, 
            visibleEditProfileSave: false,
            visibleBtnsProfile: true,
            visibleBtnsEditProfile: true,
        }
    },
    methods: {
      getDataFromIin(val){
        if (val.length == 12){
                  let date = new Date();
                  this.iinCorrect = true;
                  let year;
                  if (parseInt('20' + val.slice(0,2), 10) > date.getFullYear()) {
                    year = parseInt('19' + val.slice(0,2), 10);
                  } else {
                    year = parseInt('20' + val.slice(0,2), 10);
                  }
                  let month = val.slice(2, 4)
                  let day = val.slice(4, 6)
                    this.iinCorrect = true;
                    this.birth_date = `${year.toString()}-${month}-${day}`;
                    if (this.iin[6] == 3 || this.iin[6] == 5) {
                      this.sex = 1;
                    } else if (this.iin[6] == 4 || this.iin[6] == 6) {
                      this.sex = 2;
                    } else {
                        Vue.toastr({
                          message: this.$t('profile.settings.error'),
                          description: this.$t('profile.settings.iin-error') ,
                          type: 'error'
                        });
                    }


                }


      },
        showButtonsEdit(visibleProfileTitle, visibleBtnsProfile, visibleEditProfile, visibleProfileInfo, visiblePhotoDownload, visibleMain, visibleMainTitle, visibleEditProfileSave, visibleBtnsEditProfile){
            this.visibleProfileTitle=visibleProfileTitle;
            this.visibleBtnsProfile=visibleBtnsProfile;
            this.visibleEditProfile=visibleEditProfile;
            this.visibleProfileInfo=visibleProfileInfo;
            this.visiblePhotoDownload=visiblePhotoDownload;
            this.visibleMain=visibleMain;
            this.visibleMainTitle=visibleMainTitle;
            this.visibleEditProfileSave=visibleEditProfileSave;
            this.visibleBtnsEditProfile=visibleBtnsEditProfile;
      },

        updateProfile() {
            this.$http.post(window.API_ROOT + '/api/user/profile/update?account[iin]='+this.iin+'&account[last_name]='+this.last_name+'&account[first_name]='+this.first_name+'&account[patronymic]='+this.patronymic+'&account[birth_date]='+this.birth_date+'&account[phone]='+this.currentPhone+'&account[email]='+this.email+'&account[sex]='+this.sex)
                .then((res) => {
                    Vue.toastr({
                      message: this.$t('profile.settings.success')+'!',
                      description: this.$t('profile.settings.success'),
                      type: 'success'
                    });
                }).catch((e) => {
                    Vue.toastr({
                        message:  this.$t('profile.settings.error'),
                        description: this.$t('profile.settings.profile-error'),
                        type: 'error'
                    });
                });
            this.getInfo()
        },
        enforcePhoneFormat() {
          this.$v.phone.$touch();

          let x = this.phone
              .replace(/\D/g, "")
              .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
          this.phone = !x[2]
              ? x[1]
              : "+" + x[1] + " (" + x[2] + ")" + (x[3] ? " " + x[3] : "") + (x[4] ? " " + x[4] : "") + (x[5] ? "-" + x[5] : "");

          this.currentPhone = x[0];




            // this.$v.phone.$touch();
            // let x = this.phone
            // .replace(/\D/g, "")
            // .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
            //
            // this.currentPhone = x[0];
        },
        handleAvatarSuccess(res, file) {
            this.$router.go()
            this.avatarUrl = URL.createObjectURL(file.raw);

        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isPNG = file.type === 'image/png';
            const isLt10M = file.size / 1024 / 1024 < 10;

            if (!(isJPG || isPNG)) {
            this.$message.error('Avatar picture must be JPG or PNG format!');
            }
            if (!isLt10M) {
            this.$message.error('Avatar picture size can not exceed 10MB!');
            }
            return (isJPG || isPNG) && isLt10M;
        },
      getInfo(){
        this.loadData=true
        this.$http.get(window.API_ROOT + '/api/user/edit')
            .then((res) => {
              this.avatar = window.API_ROOT + '/api/user/avatar?file=' +res.body.data.avatar;
              this.iin=res.body.data.iin
              this.first_name=res.body.data.first_name
              this.last_name=res.body.data.last_name
              this.patronymic=res.body.data.patronymic
              if(this.patronymic==null){
                this.patronymic=''
              }
              this.sex=res.body.data.sex
              this.birth_date=res.body.data.birth_date
              this.email=res.body.data.email

              if (res.body.data.phone) {
                this.phone=res.body.data.phone
                this.enforcePhoneFormat();
              }

              this.loadData=false

            })
            .catch((e) => {
              if (e.status === 401){
                this.$router.push('/')
              }
            });
      },

      logoutMethod() {
            this.logout();
            localStorage.setItem('token', '');
            localStorage.setItem('user', '');
            sessionStorage.setItem('avatar-url', '')
            window.location = '/';
            // this.$router.push('/');
        },
        ...mapActions({
            'logout': 'logout'
        }),
    },
  mounted() {
        this.getInfo()
        setTimeout(() => {
            this.$bvModal.show('modal-to-survey')
        }, 3000);
        if ($('body').width() <= 992) {
            this.visibleMain=false;
        }
        else {
            this.visibleMain=true;
            this.visibleEditProfileSave=true;
            this.visiblePhotoDownload=true;
        }
    },
    beforeCreate() {
        Vue.use(VueMask);
        this.$http.get(window.API_ROOT + '/api/user/edit')
        .then((res) => {
            sessionStorage.setItem('avatar-url', res.data.link);
            this.avatarUrl = res.data.link;
            this.account = res.data.data;
            this.regionsLoading = true;
            this.$http.get(window.API_ROOT + '/api/references/regions/')
                .then((res) => {
                    if (res.body.data) {
                    this.regions = res.body.data;
                    this.regionsLoading = false;
                    }
                }).catch((e) => {
                this.regionsLoading = false;
            });
            if (res.data.data.phone) {
                this.phone = res.data.data.phone;
                this.enforcePhoneFormat();
            }
        })
        .catch((e) => {
            if (e.status === 401){
                Vue.toastr({
                message: 'Warning',
                description: 'Access denied',
                type: 'error'
                });
                this.logout();
                this.logoutMethod();
            }
        });
      this.getInfo()
      setTimeout(() => {
        this.$bvModal.show('modal-to-survey')
      }, 3000);
      if ($('body').width() <= 992) {
        this.visibleMain=false;
      }
      else {
        this.visibleMain=true;
        this.visibleEditProfileSave=true;
        this.visiblePhotoDownload=true;
      }

        },
    validations: {
        iin: { required, minLength: minLength(11) },
        last_name: { required },
        first_name: { required },
        patronymic: { required },
        sex: { required },
        birth_date: { required },
        email: { email },
        phone: { required, minLength: minLength(11) },
    }
}
</script>
<style>
.form-group{
    display: flex;
    flex-direction: column;
}
form .avatar-uploader .btn-wrap{
  border: 1px dashed #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  overflow: hidden;
  margin-top: 110px;
}
form .avatar-uploader .btn-light{
  background: #fff;
  padding: 10px 15px;
  box-shadow: 0 2px 6px #ccc;
  line-height: 22px;
}
form .avatar-uploader .btn-light svg{
  float: left;
  margin-right: 8px;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar-uploader .avatar {
  float: left;
  width: 178px;
  height: 178px;
  border-radius: 178px;
  display: block;
  margin-right: 64px;
  object-fit: cover;
}

@media (max-width: 992px) {
    .title, .form-sub-title {
        display: none;
    }
    .avatar-uploader .avatar {
        margin: 0 auto;
    }
    .avatar-uploader .el-upload {
        display: flex;
        flex-direction: column;
    }
    .profile-parent-edit-form .avatar-uploader .btn-wrap {
        width: fit-content;
        margin: 28px auto 31px auto;
    }
    .content .profile-edit-form {
        box-shadow: none;
    }
}    
</style>